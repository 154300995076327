

import { gmapApi } from 'vue2-google-maps';
import * as geolib from 'geolib';

export default {
  components: {

  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      alert: false,
      simpan1: false,
      url : {
        GET_URL : this.$store.state.url.URL_APP + "api/v1/web_home/",
        PETA_DASAR: this.$store.state.url.URL_APP + "api/v1/petadasarnew/"

      },
      date : '',
      petadasar: [],
      users : null,
      peta_final : null,


      list_data : [],
      jumlah_zona : null,
      jumlah_titik : null,
      no: 0,

      form : {
            kecamatan: '',
          },
      kecamatan:[],


      clusterOptions: {
            gridSize: 30,
            maxZoom: 15,
        },
              
        ///////////////////////TITIK/////////////////////////////////
        polygonZonaOptions: {
          fillColor: 'blue',
          fillOpacity: 0.5,
          strokeOpacity: 1,
          strokeWeight: 1,
          // Opsi warna dan kecerahan arsiran polygon
        },

        polygons_titik: [],
            
            polygonTitikOptions: {
              fillColor: 'blue',
              fillOpacity: 0.5,
              // Opsi warna dan kecerahan arsiran polygon
            },

            markers_titik: [] ,
          

          markerIcon: {
              url: 'img/a.png',
              scaledSize: { width: 40, height: 40 },
            },

          
            ///////////////////////////END TITIK///////////////////////////// 
    }
  },
  methods: {

    cetak() {
        this.$htmlToPaper('printMe');
      },
    
    calculateArea(data) {

      var area = geolib.getAreaOfPolygon(data);
      return (area/1000000).toFixed(9);
    },
    cari_data: function() {
        
        // this.polygons = [];
      this.getPetadasar();
      
    },
    mapping: function(data) {

      var tampung = [];
      // console.log(data.length);

      for(var i = 0; i < data.length; i++) {
          var obj = data[i];
          tampung.push({"lat":parseFloat(obj[1]),"lng":parseFloat(obj[0])});
      }

      // console.log(tampung);
      return tampung;

    },
   
    getKecamatan: function() {
                this.cek_load_data = true;
                fetch(this.url.PETA_DASAR + "kecamatan_all", {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                  },

                })
                  .then(res => res.json())
                  .then(res_data => {
                      
                    var tampung=[];

                    var kode = '';

                      for(var i=0;i<res_data.length;i++){

                        if(res_data[i].hasil.kode<10){
                            kode = `0${res_data[i].hasil.kode}`;
                        }
                        else{
                            kode = `${res_data[i].hasil.kode}`
                        }


                        tampung.push({
                          // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.kode}`, 
                          kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.${kode}`, 
                          nama_kecamatan: res_data[i].hasil.uraian
                        })
                      }

                    
                      this.kecamatan = tampung;
                      this.cek_load_data = false;
                  });
            },

    getPetadasar: function() {
            this.cek_load_data = true;

            console.log();
            fetch(this.url.GET_URL + "petadasar", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    kecamatan_id : this.form.kecamatan,
                  })
                })
                .then(res => res.json())
                .then(res_data => {

                    this.petadasar = res_data;
                    this.cek_load_data = false;
                    
                    
                // 


                });
            },

      getZona: function() {
        fetch(this.url.GET_URL + "zona", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
           
          })
          .then(res => res.json())
          .then(res_data => {
  
            
            this.jumlah_zona = res_data[0]
            
            // 
  
  
          });
      },

    getTitik: function() {
        fetch(this.url.GET_URL + "titik", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
           
          })
          .then(res => res.json())
          .then(res_data => {
  
            
            this.jumlah_titik = res_data[0]
            
            // 
  
  
          });
      },

    getUsers: function() {
        fetch(this.url.GET_URL + "users", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
           
          })
          .then(res => res.json())
          .then(res_data => {
  
            
            this.users = res_data[0]
            
            // 
  
  
          });
      },

    getPetafinal: function() {
        fetch(this.url.GET_URL + "peta_final", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
           
          })
          .then(res => res.json())
          .then(res_data => {
  
            
            this.peta_final = res_data[0]
            
            // 
  
  
          });
      },



  },

  computed: {
    google: gmapApi
},
  mounted () {
    this.getZona();
    this.getTitik();
    this.getKecamatan();
    this.getPetadasar();
    this.getUsers();
    this.getPetafinal();
  },
}
