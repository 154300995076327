
export default {
  data() {
    return {
      leftDrawerOpen: true,
      nama:'',
      foto:'',
      status:'',
      file_path: this.$store.state.url.URL_APP + "uploads/",
    };
  },

  methods: {
    logout : function(){
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        this.$router.push('/login');
    },
  },
  mounted() {
    var get_profile = JSON.parse(localStorage.profile);
    this.nama = get_profile.profile.nama;
    this.foto = get_profile.profile.foto;
    this.status = get_profile.profile.status;

    

            
    }

};
