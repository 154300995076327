import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter);


function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}



  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },

  // {
  //   path: '/usulanLokasiAbsen',
  //   name: 'usulanLokasiAbsen',
  //   component: () => import('../views/verifikasiLokasiAbsen/usulanLokasiAbsen.vue'),
  //   beforeEnter: isLoggedIn,
  // },


  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import('../views/monitoring.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/zona',
    name: 'zona',
    component: () => import('../views/zona.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterLevel',
    name: 'masterLevel',
    component: () => import('../views/dataMaster/masterLevel.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/zonaTanah',
    name: 'zonaTanah',
    component: () => import('../views/dataMaster/zonaTanah.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/zonaTanahSanggah',
    name: 'zonaTanahSanggah',
    component: () => import('../views/dataMaster/zonaTanahSanggah.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/petadasar',
    name: 'petadasar',
    component: () => import('../views/dataMaster/petadasar.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/petadasarnew',
    name: 'petadasarnew',
    component: () => import('../views/dataMaster/petadasarnew.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/petafinal',
    name: 'petafinal',
    component: () => import('../views/petafinal.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/pengguna',
    name: 'pengguna',
    component: () => import('../views/auth/pengguna.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    beforeEnter: isLoggedIn,
  },


]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
